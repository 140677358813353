import React from "react";
import { Button, Card, Col, Container, Navbar, Nav, Row, Image } from "react-bootstrap";
import LoginButton from "../features/components/LoginButton";
import NavBarLogo from "../features/components/NavBarLogo";
import main_png from "../assets/main_1070x1070.png";
import editor_png from "../assets/editor_1070x1070.png";
import evergreen_png from "../assets/evergreen_1070x1070.png";
import regen_png from "../assets/regen_1070x1070.png";
import episode_01 from "../assets/samples/episode_01.wav";
import intro from "../assets/samples/intro.wav";
import shape_place_web_host from "../assets/samples/shape_place_web_host.wav";
import weed_gummies from "../assets/samples/weed_gummies.wav";
import { ArrowRight } from "react-bootstrap-icons";

function Landing() {
  return (
    <div className="App">
      <Navbar bg="primary" variant="dark" expand="md">
        <Container>
          <NavBarLogo />
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="order-first" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#how-to">How It Works</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
              <Nav.Link href="#api">API</Nav.Link>
            </Nav>
            <Nav className="justify-content-end">
              <Navbar.Text>
                <LoginButton className="plausible-event-name=Login plausible-event-position=Header"/>
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="landing-page">
        <Container id="home" className="landing-section my-5">
          <Row className="spacer-row"></Row>
          <Row className="g-3">
            <Col className="home-text-col" md="6">
              <Row>
                <h1>Daisy.fm</h1>
              </Row>
              <Row>
                <h2>Dynamic Audio Injection for Podcasts</h2>
              </Row>
              <Row>
                <p className="lead">
                  Daisy automatically inserts audio snippets into your podcast episode at a chosen timestamp. Update an
                  audio snippet, and episodes are re-generated automatically. All new downloads contain the new audio
                  inserts.
                </p>
              </Row>
              <Row xs="2">
                <div className="">
                  <LoginButton className="plausible-event-name=Login plausible-event-position=Landing+Primary">Sign Up</LoginButton>
                </div>
              </Row>
            </Col>
            <Col className="home-image-col" md="6">
              <Image className="d-block m-auto" src={main_png} fluid={true}></Image>
            </Col>
          </Row>
        </Container>
        <Container id="features" className="landing-section py-5">
          <Row className="my-4">
            <h2>Features</h2>
          </Row>
          <Row className="g-4">
            <Col xs="12" md="4">
              <Card className="h-100">
                <Card.Img variant="top" src={editor_png} className="landing-feature-image" />
                <Card.Body>
                  <Card.Title>Dynamic Ad Injection</Card.Title>
                  <Card.Text>
                    No more manually editing ads into your episodes! Using Daisy's interactive editor choose a timestamp
                    and which ad campaign should be injected. Daisy will generate the final episode audio.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="h-100">
                <Card.Img variant="top" src={regen_png} />
                <Card.Body>
                  <Card.Title>Easy Updates to Intros, Themes, and Ads</Card.Title>
                  <Card.Text>
                    Place intro or theme music at the appropriate point in your episode. Change an audio snippet, or
                    switch to a different ad campaign, and episodes are re-generated.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="h-100">
                <Card.Img variant="top" src={evergreen_png} />
                <Card.Body>
                  <Card.Title>Evergreen Content</Card.Title>
                  <Card.Text>
                    Upload new ads to replace old ads, and we'll re-generate all episodes to feature the new ad audio.
                    This keeps your back-catalogue fresh with content from your most recent sponsors, without you having
                    to do any re-editing.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container id="how-to" className="landing-section py-5">
          <Row className="my-4">
            <h2>How It Works</h2>
          </Row>
          <Row className="g-4">
            <Col xs="12" md="3">
              <Card className="h-100">
                <Card.Header className="how-to-card-header">Step 1</Card.Header>
                <Card.Body>
                  <Card.Title>Upload Episodes and Inserts</Card.Title>
                  Create an episode and upload an audio file. You can use the files below as samples to get started:
                  <br />
                  <br />
                  <ul>
                    <li>
                      <a href={episode_01}>episode_01.wav</a>
                    </li>
                  </ul>
                  Next, upload the inserts - for example an intro theme, ads, or fundraising spots.
                  <br />
                  <br />
                  <ul>
                    <li>
                      <a href={intro}>intro.wav</a>
                    </li>
                    <li>
                      <a href={shape_place_web_host}>shape_place_web_host.wav</a>
                    </li>
                    <li>
                      <a href={weed_gummies}>weed_gummies.wav</a>
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="3">
              <Card className="h-100">
                <Card.Header className="how-to-card-header">Step 2</Card.Header>
                <Card.Body>
                  <Card.Title>Create Tags and Edit your Episode</Card.Title>
                  Label each insert with a tag.
                  <br />
                  <br />
                  <ul>
                    <li>
                      Intro <ArrowRight /> intro.wav
                    </li>
                    <li>
                      Midroll <ArrowRight /> shape_place_web_host.wav
                    </li>
                  </ul>
                  Then, edit the episode to choose where in your episode to place a tag.
                  <br />
                  <br />
                  <ul>
                    <li>Intro @ 00m:01.0s</li>
                    <li>Midroll @ 00m:02.2s</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="3">
              <Card className="h-100">
                <Card.Header className="how-to-card-header">Step 3</Card.Header>
                <Card.Body>
                  <Card.Title>Download or RSS Feed</Card.Title>
                  Daisy will generate new episode audio, with the inserts you specified, and make it available for
                  download. Episodes can be downloaded individually, or subscribe to an RSS feed of all episodes.
                  <br />
                  <br />
                  <ul>
                    <li>Preview in Daisy</li>
                    <li>Download File</li>
                    <li>Subscribe RSS</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="3">
              <Card className="h-100">
                <Card.Header className="how-to-card-header">Step 4</Card.Header>
                <Card.Body>
                  <Card.Title>Change Tags</Card.Title>
                  Change the tag to point to a different insert,
                  <br />
                  <br />
                  <ul>
                    <li>
                      Intro <ArrowRight /> intro.wav
                    </li>
                    <li>
                      <s>
                        Midroll <ArrowRight /> shape_place_web_host.wav
                      </s>
                    </li>
                    <li>
                      Midroll <ArrowRight /> weed_gummies.wav
                    </li>
                  </ul>
                  and Daisy will re-generate all episodes containing that tag. In this example, any episode that has the
                  "Midroll" insert.
                  <br />
                  <br />
                  New downloads, both via direct download and the RSS feed, will get the updated episode audio.
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container id="pricing" className="landing-section py-5">
          <Row className="my-4">
            <h2>Pricing</h2>
          </Row>
          <Row className="g-4">
            <Col xs="6" lg="4">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>Free</Card.Title>
                  Try out the Daisy for free. No credit card required.
                  <br />
                  <br />
                  <ul>
                    <li>1 Podcast</li>
                    <li>10 Episodes</li>
                    <li>5 Audio Snippets</li>
                  </ul>
                  <h3 className="landing-price">$0/month</h3>
                </Card.Body>
                <Card.Footer className="text-center">
                  <LoginButton className="plausible-event-name=Login plausible-event-position=Pricing+Free">Get Started</LoginButton>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs="6" lg="4">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>Indie</Card.Title>
                  Full Daisy functionality for your podcast.
                  <br />
                  <br />
                  <ul>
                    <li>1 Podcast</li>
                    <li>Unlimited Episodes</li>
                    <li>Unlimited Audio Snippets</li>
                  </ul>
                  <h3 className="landing-price">$12/month</h3>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button variant="primary" href="mailto:hello@daisy.fm" className="plausible-event-name=Contact+Us plausible-event-position=Pricing+Indie">
                    Contact Us
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs="6" lg="4">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>Producer</Card.Title>
                  Manage multiple podcasts.
                  <br />
                  <br />
                  <ul>
                    <li>Unlimited Podcasts</li>
                    <li>Unlimited Episodes</li>
                    <li>Unlimited Audio Snippets</li>
                  </ul>
                  <h3 className="landing-price"></h3>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button variant="primary" href="mailto:hello@daisy.fm" className="plausible-event-name=Contact+Us plausible-event-position=Pricing+Producer">
                    Contact Us
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container id="api" className="landing-section py-5">
          <Row className="my-4">
            <h2>API</h2>
          </Row>
          <Row className="g-4">
            <Col>
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>Daisy API</Card.Title>
                  <Card.Text>
                    Build your own applications and services, making use of Daisy's content injection functionality.
                  </Card.Text>
                  <Button variant="primary" href="mailto:hello@daisy.fm" className="plausible-event-name=Contact+Us plausible-event-position=API">
                    Contact Us
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Landing;
