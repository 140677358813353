import React, { useState } from "react";
import { Button, Card, Container, Modal } from "react-bootstrap";

interface NoPostcastsInterstitialProps {
  onClick: () => void;
}

export function NoPostcastsInterstitial(props: NoPostcastsInterstitialProps) {
  const [showModal, setShowModal] = useState(true);

  const onclick = function (): void {
    setShowModal(false);
    props.onClick();
  };

  return (
    <Modal show={showModal} onHide={props.onClick} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Welcome to Daisy</Modal.Title>
      </Modal.Header>
      <Modal.Body>The first step is to create a new podcast. Let's do that now!</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onclick}>
          Go to Podcasts
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
