import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AddEpisodeForm } from "../features/episodes/AddEpisodeForm";
import { Episode, Serial, SerialId, Marker, Tag, AudioAssetUrl } from "../features/api/interfaces";

import { useNavigate, useSearchParams } from "react-router-dom";
import { SelectPodcastForm } from "../features/episodes/SelectPodcastForm";
import {
  useGetEpisodeCutAudioUrlQuery,
  useGetEpisodesQuery,
  useGetMarkersQuery,
  useGetPodcastsQuery,
  useGetTagsQuery,
} from "../features/api/apiSlice";
import { EpisodeEditor, EpisodeEditorInputObj } from "../features/episodes/EpisodeEditor";
import { ListPage } from "../features/components/ListPage";
import AudioPlayer from "../features/components/AudioPlayer";
import { NoPostcastsInterstitial } from "../features/episodes/NoPodcastsInterstitial";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import { getEnv } from "../app/utils";

const REFETCH_INTERVAL_MS = 2000;

function getEPisodeDownloadHref(episode_id: string): string {
  let url = getEnv("REACT_APP_BACKEND_API_URL");
  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }
  return url + "/episode/" + episode_id + "/cut_audio_file";
}

interface EpisodeListItemProps {
  obj: EpisodeEditorInputObj;
  meta?: { serial: SerialId | undefined };
}

function EpisodeListItemSummary(props: EpisodeListItemProps) {
  const audioUrlRes = useGetEpisodeCutAudioUrlQuery(props.obj.episode);
  const audioMediaURL: AudioAssetUrl = { url: "", content_type: "" };
  if (audioUrlRes.isSuccess) {
    // audioMediaURL = URL.createObjectURL(audioUrlRes.data)
    audioMediaURL.url = audioUrlRes.data.url;
    audioMediaURL.content_type = audioUrlRes.data.content_type;
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!audioUrlRes.isSuccess) {
      timeout = setTimeout(() => {
        audioUrlRes.refetch();
      }, REFETCH_INTERVAL_MS);
    }
    return () => clearTimeout(timeout);
  }, [audioUrlRes]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <AudioPlayer audioURL={audioMediaURL.url} />
        </Col>
        <Col xs="auto">
          <Button variant="primary" href={getEPisodeDownloadHref(props.obj.episode.id)} className="plausible-event-name=Episode+Manual+Download">
            <FileEarmarkArrowDown />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function EpisodeListItemDetail(props: EpisodeListItemProps) {
  return (
    <React.Fragment>
      <Row>
        ID: {props.obj.episode.id}
        <br />
        Name: {props.obj.episode.name}
      </Row>
    </React.Fragment>
  );
}

function assembleEpisodeEditorInputObjs(
  selectedPod: SerialId | undefined,
  episodes: Episode[] | undefined,
  markers: Marker[] | undefined
): EpisodeEditorInputObj[] {
  if (!selectedPod || !episodes) {
    return [];
  }

  // TODO bubble down markers or tags not being loaded
  return episodes
    .filter((value: Episode, index: number, array: Episode[]) => value.serial_id === selectedPod)
    .map<EpisodeEditorInputObj>((value: Episode, index: number, array: Episode[]) => ({
      id: value.id.toString(),
      name: value.name,
      episode: value,
      markers: markers ? markers.filter((m: Marker) => m.episode_id === value.id) : [],
    }));
}

function Episodes() {
  const podcastsRes = useGetPodcastsQuery();
  const episodesRes = useGetEpisodesQuery();
  const tagsRes = useGetTagsQuery();
  const markersRes = useGetMarkersQuery();
  // for button that goes to Podcasts page
  const navigate = useNavigate();
  const goToPodcasts = useCallback(() => navigate("../podcasts", { replace: true }), [navigate]);

  //https://reactrouter.com/docs/en/v6/getting-started/tutorial#search-params
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPodId, setSelectedPodId] = useState<SerialId | undefined>(() => {
    const str = searchParams.get("serial");
    if (str) {
      // TODO validation and parsing
      return str as SerialId;
    }
    return undefined;
  });

  function onPodSelected(id: SerialId, name: string): void {
    console.log("Episodes/onPodSelected");
    console.log("Episodes/onPodSelected name :" + name);
    console.log("Episodes/onPodSelected id :" + id);
    setSelectedPodId(id);
    setSearchParams({ serial: id as string });
  }

  useEffect(() => {
    if (selectedPodId) {
      setSearchParams({ serial: selectedPodId.toString() });
    }
  }, [selectedPodId, setSearchParams]);

  let initialSelect: SerialId | undefined = selectedPodId;

  let podcasts: [SerialId, string][] = [];
  let episodes: Episode[] | undefined = undefined;
  let markers: Marker[] | undefined = undefined;
  let tags: Tag[] | undefined = undefined;

  if (podcastsRes.isLoading) {
    //content = <Spinner text="Loading..." />
  } else if (podcastsRes.isSuccess) {
    // content = res.data.map((tag: Tag) => <ListGroup.Item key={tag.id}>{tag.name}</ListGroup.Item>)
    podcasts = podcastsRes.data.map((podcast: Serial) => [podcast.id, podcast.name]);
    if (podcasts.length && selectedPodId === undefined) {
      initialSelect = podcasts[0][0];
      setSelectedPodId(podcasts[0][0]);
      // setSearchParams({ serial: initialSelect as string });
    }
  } else if (podcastsRes.isError && podcastsRes.error) {
    // content = <div>{res.error.toString()}</div>
  }

  // TODO error handling for each
  if (episodesRes.isSuccess) {
    episodes = episodesRes.data;
  }
  if (markersRes.isSuccess) {
    markers = markersRes.data;
  }
  if (tagsRes.isSuccess) {
    tags = tagsRes.data;
  }

  const episodeEditorObjs = assembleEpisodeEditorInputObjs(selectedPodId, episodes, markers);

  console.log("Episodes/Render");
  console.log("Episodes/Render selectedPodId: " + selectedPodId);
  console.log("Episodes/Render initialSelect: " + initialSelect);
  console.log("Episodes/Render searchParams " + searchParams.values());
  searchParams.forEach((value: string, key: string, parent: URLSearchParams) =>
    console.log("Episodes/Render searchParams: %s:%s", key, value)
  );

  let modal = <React.Fragment />;
  if (podcastsRes.isSuccess && !podcasts.length) {
    modal = <NoPostcastsInterstitial onClick={goToPodcasts} />;
  }
  if (podcastsRes.isSuccess) {
    return (
      <React.Fragment>
        <ListPage
          id="episodes"
          objName="Episode"
          objNamePlural="Episodes"
          objs={episodeEditorObjs}
          meta={{ serial: selectedPodId, tags: tags ? tags : [] }}
          HeaderComp={
            <SelectPodcastForm podcasts={podcasts} initialSelect={initialSelect} onPodSelected={onPodSelected} />
          }
          AddObjForm={AddEpisodeForm}
          EditObjForm={EpisodeEditor}
          ObjSummaryComponent={EpisodeListItemSummary}
          ObjDetailComponent={EpisodeListItemDetail}
        />
        {modal}
      </React.Fragment>
    );
  }
  return <React.Fragment />;
}

export default Episodes;
